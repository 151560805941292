export default class PostPhotos {
  constructor(el) {
    this.el = el
    this.mainPhoto = this.el.querySelector('.post-main-photo')
    this.thumbs = this.el.querySelectorAll('.post-photo-thumb')
    this.binds()
  }

  binds() {
    this.mainPhoto.addEventListener('click', (e) => this.mainPhotoClicked(e))
    this.thumbs.forEach((thumb) => {
      thumb.addEventListener('click', (e) => this.thumbClicked(e))
    })
  }

  mainPhotoClicked(e) {
    e.preventDefault()

    if (this.thumbs.length) {
      this.openBoxByThumbs()
    } else {
      this.openBox([this.boxAttrs(this.mainPhoto)])
    }
  }

  thumbClicked(e) {
    e.preventDefault()
  }

  openBoxByThumbs() {
    const boxElements = Array.from(this.thumbs).flatMap((thumb) => this.boxAttrs(thumb));
    this.openBox(boxElements);
  }

  openBox(elements) {
    $.fancybox.open(elements, {
      thumbs: {
        autoStart: true,
        axis: "x"
      },
      toolbar: false
    })
  }

  boxAttrs(link) {
    const thumb = link.querySelector('img').getAttribute('src')
    return {
      src: link.getAttribute('href'),
      opts: { thumb: thumb }
    }
  }
}
