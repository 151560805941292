import authenticityToken from '../shared/helpers/authenticity_token'

export default function fileUpload(input, options = {}) {
  const callbacks = {
    start: options['start'],
    success: options['success'],
    error: options['error'],
  }

  const url = options['url'];
  const method = options['method'];

  let formData = new FormData();
  formData.append('authenticity_token', authenticityToken());
  for (const item in options['formData']) {
    const value = options['formData'][item]
    formData.append(item, value);
  };

  input.addEventListener("change", function (event) {
    const defaultErrorMessage = 'Произошла ошибка при загрузке файла';

    formData.append(input.name, input.files[0]);

    callbacks.start();

    fetch(url, { method: method, body: formData })
      .then(async (response) => {
        let json = await response.json();
        if (response.ok) {
          callbacks.success(json)
        } else {
          let message;
          if (json['base']) {
            message = json['base'].join(' ')
          }
          callbacks.error(message || defaultErrorMessage);
        }

        input.value = null;
      })
      .catch(() => { callbacks.error(defaultErrorMessage) });
  })
}
