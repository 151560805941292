export default function arrayInColumns(arr, n) {
  const result = [];
  const base = Math.ceil(arr.length / n);
  for (let i = 0; i < arr.length; i++) {
    const el = arr[i];
    const k = Math.floor(i / base);
    if (!result[k]) { result[k] = []; }
    result[k].push(el);
  }
  return result;
};
