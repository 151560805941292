import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="centering-horizontal-scrolling"
export default class extends Controller {
  connect() {
    const activeItem = this.element.querySelector('.active');
    if (activeItem) {
      const oneThirdOfViewArea = this.element.offsetWidth / 3;
      if (activeItem.offsetLeft > oneThirdOfViewArea) {
        this.element.scrollLeft = activeItem.offsetLeft - oneThirdOfViewArea;
      }
    }
  }
}
