import authenticityToken from '../../shared/helpers/authenticity_token'

export default class StiPhotoFields {
  constructor(options) {
    this.finishUploading = this.finishUploading.bind(this);
    this.startUploading = this.startUploading.bind(this);
    this.insertPhotoField = this.insertPhotoField.bind(this);
    this.renderPhotoField = this.renderPhotoField.bind(this);
    this.fileUploadBind = this.fileUploadBind.bind(this);
    this.options = options;
    this.container = options.container;
    this.imageableName = options.imageableName;
    this.imageableId = options.imageableId;
    this.imageableType = options.imageableType;
    this.addBtn = this.container.querySelector('.add-photo');
    this.fileInput = this.container.querySelector('input[type=file]');
    this.binds();
  }

  finishUploading() {
    return this.addBtn.classList.remove('-uploading');
  }

  startUploading() {
    return this.addBtn.classList.add('-uploading');
  }

  insertPhotoField(photo) {
    var newField;
    newField = this.renderPhotoField(photo);
    return this.addBtn.parentNode.insertAdjacentHTML('beforebegin', newField);
  }

  renderPhotoField(photo) {
    return `<div class="photo-field" id="photo_${photo.id}">
  <input type="hidden" name="${this.imageableName}[photo_ids][]" value=${photo.id}>
  <img src=${photo.file.thumb.url}>
  <a class="btn btn-danger btn-mini remove" href="/images/${photo.id}">
    <i class="bi bi-trash"></i>
  </a>
</div>`;
  }

  binds() {
    var s;
    this.fileUploadBind();
    Sti.Lib.on('.remove', 'click', this.removeBtnClicked, this.container);
    return s = Sti.Lib.sorting(this.container, 'images', {
      touch: 'imageable',
      placeholderClass: 'photo-field',
      orientation: 'horizontal',
      // placeholder: 'photo-field-placeholder',
      items: '.photo-field',
      handle: 'img'
    });
  }

  fileUploadBind() {
    var formData;
    this.photoType = this.options.photoType;
    formData = {
      type: this.photoType
    };
    if (this.options.imageableId) {
      formData["image[imageable_id]"] = this.options.imageableId;
      formData["image[imageable_type]"] = this.options.imageableType;
    }
    return Sti.Lib.fileUpload(this.fileInput, {
      dataType: 'json',
      url: '/images',
      method: 'POST',
      formData: formData,
      start: () => {
        return this.startUploading();
      },
      success: (photo) => {
        this.insertPhotoField(photo);
        return this.finishUploading();
      },
      error: (errorMessage) => {
        alert(errorMessage);
        return this.finishUploading();
      }
    });
  }

  removeBtnClicked(event) {
    var formData, photoField, removeBtn, url;
    event.preventDefault();
    removeBtn = this;
    photoField = removeBtn.parentNode;
    url = removeBtn.getAttribute('href');
    formData = new FormData();
    formData.append('authenticity_token', authenticityToken());
    return fetch(url, {
      method: 'delete',
      body: formData
    }).then(function (r) {
      return r.json;
    }).then(function () {
      return photoField.remove();
    });
  }
};
