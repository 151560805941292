import { Controller } from "@hotwired/stimulus";
import Combobox from "@github/combobox-nav";
import { useClickOutside, useDebounce } from "stimulus-use";
import fetchJsonWithCsrf from "../src/shared/helpers/fetch_json_with_csrf";
import renderTemplate from '../src/shared/helpers/render_template';

// Connects to data-controller="main-search"
export default class extends Controller {
  static debounces = ['search'];
  static targets = ["input", "list", "item"];

  connect() {
    useClickOutside(this);
    useDebounce(this, { wait: 500 });

    this.combobox = new Combobox(this.inputTarget, this.listTarget);
    this.arrowMapping = new Map([["ArrowDown", 1], ["ArrowUp", -1]]);
  }

  disconnect() {
    this.combobox.destroy();
  }

  show() {
    this.listTarget.classList.add("shown");
    this.combobox.start();
  }

  hide() {
    this.listTarget.classList.remove("shown");
    this.combobox.clearSelection();
    this.combobox.stop();
  }

  search() {
    const query = this.inputTarget.value;

    if (query.length < 3) return this.hide();

    this.searchRequest(query)
      .then(({ collections }) => {
        if (collections.length === 0) {
          this.listTarget.innerHTML = renderTemplate("shared/main_search/blank");;
        } else {
          const html = renderTemplate("shared/main_search/results", { collections });
          this.listTarget.innerHTML = html;
        }

        this.show();
      }).catch(() => {
        this.listTarget.innerHTML = renderTemplate("shared/main_search/error", {});;
        this.show();
      });
  }

  searchRequest(query) {
    return fetchJsonWithCsrf(`/search?value=${query}`)
  }

  // Only triggers search on closed list, doesn't handle traversing open list
  arrowSearch(event) {
    if (!this.arrowMapping.has(event.key)) return;
    if (!this.listTarget.hidden) return;

    this.search();
    if (this.listTarget.hidden) return;

    this.combobox.navigate(this.arrowMapping.get(event.key));
  }

  // Does not work for clicks outside controller (no relatedTarget set),
  // but this handles tabbing away and useClickOutside handles clicks.
  blur(event) {
    if (this.listTarget.hidden) return;

    if (!event.relatedTarget) return;
    if (this.element.contains(event.relatedTarget)) return;

    this.hide();
  }

  clickOutside(event) {
    if (this.listTarget.hidden) return;

    this.hide();
  }

  commit(event) {
    const url = event.target.dataset.url
    if (url) window.location = url

    this.hide();
  }
}
