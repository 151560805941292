module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"event-item ")
    ;  if (hidden) { 
    ; __append("-hidden")
    ;  } 
    ; __append("\">\n  <div class=\"meta event-meta\">\n    <div class=\"author\">\n      ")
    ;  if (event.author) { 
    ; __append("\n        <a href=\"/admin/users/")
    ; __append(escapeFn( event.author.id ))
    ; __append("\">\n          ")
    ; __append(escapeFn( event.author.title ))
    ; __append("\n        </a>\n        ")
    ;  } else { 
    ; __append("\n          Robot\n          ")
    ;  } 
    ; __append("\n    </div>\n    <div class=\"time\">\n      ")
    ; __append(escapeFn( event.created_at_formatted ))
    ; __append("\n    </div>\n  </div>\n  <div class=\"content\">")
    ; __append( event.content )
    ; __append("</div>\n  <div class=\"actions\">\n    ")
    ;  if (event.can_update) { 
    ; __append("\n      <i class=\"bi bi-pencil\" role=\"edit\"></i>\n      ")
    ;  } 
    ; __append("\n  </div>\n  <form url=\"/admin/events/")
    ; __append(escapeFn( event.id ))
    ; __append("\" class=\"event-form\" role='edit_event_form'>\n    <textarea id=\"user_event_content\" rows=\"3\">")
    ; __append(escapeFn( event.content ))
    ; __append("</textarea>\n    <div class=\"error\" id=\"user_event_error\"></div>\n    <p class=\"actions\">\n      <button class=\"btn btn-primary btn-small\" type=\"submit\">Сохранить</button>\n    </p>\n  </form>\n</div>\n")
  }
  return __output;

}