module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class='two-columns-modal region-changer modal hide' id='region_changer' tabindex='-1'>\n  <div class='modal-header'>\n    <button aria-hidden class='close' data-dismiss='modal' type='button'>×</button>\n    <h3>Выберите регион</h3>\n  </div>\n\n  <div class='modal-body'>\n    <div class='left-column districts' id='districts'>\n      <div class='header'>Округ</div>\n      <ul>\n        ")
    ;  districts.forEach(function(district) { 
    ; __append("\n          <li class='left-column-item district'>\n            <a class='left-column-item-title\n              ")
    ;  if (currentRegion && currentRegion.federal_district_id == district.id) { 
    ; __append("\n                active\n              ")
    ;  } 
    ; __append("' href='#district_")
    ; __append(escapeFn( district.id ))
    ; __append("_regions'>\n              <span>\n                ")
    ; __append(escapeFn( district.title ))
    ; __append("\n              </span>\n            </a>\n          </li>\n          ")
    ;  }) 
    ; __append("\n      </ul>\n    </div>\n    <div class='right-column' id='district_regions'>\n      <div class='header'>Область, край, республика</div>\n      ")
    ;  districts.forEach(function(district) { 
    ; __append("\n        <ul class='right-column-items\n              ")
    ;  if (currentRegion && currentRegion.federal_district_id == district.id) { 
    ; __append("\n                active\n              ")
    ;  } 
    ; __append("' id='district_")
    ; __append(escapeFn( district.id ))
    ; __append("_regions'>\n\n          ")
    ;  regions=regionsByFederalDistrictId(district.id) 
    ; __append("\n            ")
    ;  regions.forEach(function(region) { 
    ; __append("\n              <li>\n                <a href=\"")
    ; __append(escapeFn( changeUrlSubdomain(location.href, region.subdomain) ))
    ; __append("\">\n                  <span>\n                    ")
    ; __append(escapeFn( region.name ))
    ; __append("\n                  </span>\n                </a>\n              </li>\n              ")
    ;  }) 
    ; __append("\n        </ul>\n        ")
    ;  }) 
    ; __append("\n\n          ")
    ;  if (!currentRegion) { 
    ; __append("\n            <ul class=\"right-column-items active\">\n              <li>← Выберите федеральный округ</li>\n            </ul>\n            ")
    ;  } 
    ; __append("\n    </div>\n  </div>\n</div>\n")
  }
  return __output;

}