module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"blank\">\n  <p>\n    По Вашему запросу ничего не найдено. Попробуйте дописать в строку поиска название города, в которым вы ищете или\n    пройдите напрямую в\n    <a href='/arenda'>рубрику аренды спецтехники</a>,\n    <a href='/zayavki'>заявок на аренду</a> или\n    <a href='/uslugi'>спецобъявлений</a>.\n  </p>\n</div>\n")
  }
  return __output;

}