import MobileModal from "./mobile/modal";
import Data from '../shared/data.js';

import renderTemplateHTML from '../shared/helpers/render_template_html';
import regionsByFederalDistrictId from '../shared/helpers/regions_by_federal_district_id';
import changeUrlSubdomain from "../shared/helpers/change_url_subdomain.js";
import getCurrentRegion from "../shared/helpers/get_current_region.js";
import isMobileVersion from "../shared/helpers/is_mobile_version";

let mobileChanger, desktopChanger;

document.querySelectorAll(".change-region").forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();

    if (isMobileVersion()) {
      mobileChanger ||= new MobileRegionChanger();
      mobileChanger.show()
    } else {
      desktopChanger ||= new DesktopRegionChanger();
      desktopChanger.show()
    }
  })
})

class DesktopRegionChanger {
  constructor() {
    this.container = renderTemplateHTML('shared/region_changer/show', {
      changeUrlSubdomain: changeUrlSubdomain,
      regionsByFederalDistrictId: regionsByFederalDistrictId,
      currentRegion: getCurrentRegion(),
      districts: Data.federalDistricts
    })[0];
    
    document.querySelector('body').appendChild(this.container);

    this.binds()
  }

  binds() {
    const districts = this.container.querySelectorAll('#districts a');
    districts.forEach((el) => {
      el.addEventListener('click', (event) => {
        const selected = event.currentTarget;

        if (!selected.classList.contains('active')) {
          const districtRegionsSelector = selected.getAttribute('href');
          const activeEls = this.container.querySelectorAll('#districts .active, #district_regions .active');
          activeEls.forEach((el) => el.classList.remove('active'));

          this.container.querySelector(districtRegionsSelector).classList.add('active');
          selected.classList.add('active');
        }
      })
    })
  }

  show() {
    $(this.container).modal('show')
  }
}

class MobileRegionChanger {
  constructor() {
    this.container = renderTemplateHTML('shared/mobile_region_changer/show', {
      changeUrlSubdomain: changeUrlSubdomain,
      regionsByFederalDistrictId: regionsByFederalDistrictId,
      currentRegion: getCurrentRegion(),
      districts: Data.federalDistricts
    })[0];

    document.querySelector('body').appendChild(this.container);
    this.modal = new MobileModal(this.container)
  }

  show() {
    this.modal.show()
  }
}
