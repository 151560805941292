import { Controller } from "@hotwired/stimulus"
import fetchJsonWithCsrf from "../src/shared/helpers/fetch_json_with_csrf"

// Connects to data-controller="destroy-in-table-row"
export default class extends Controller {
  static values = { url: String }

  connect() {
    if (this.hasUrlValue) {
      this.element.addEventListener("click", (e) => this.destroyBtnClicked(e))
    }
  }

  destroyBtnClicked(event) {
    event.preventDefault()

    if (confirm('Уверены, что хотите удалить?')) {
      const row = event.target.closest("tr")

      fetchJsonWithCsrf(this.urlValue, { method: 'delete' })
        .then(data => {
          row.classList.add("animated", "fadeOut")

          setTimeout(() => row.remove(), 500)
        })
    }
  }
}
