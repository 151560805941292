import { Controller } from "@hotwired/stimulus"
import PostCard from '../../src/general/post/card.js'
import isElementInViewport from '../../src/shared/helpers/is_element_in_viewport'

// Connects to data-controller="catalog--infinite-scroll"
export default class extends Controller {
  static targets = ['postList', 'trigger']

  connect() {
    this.state = 'loaded';
    this.endOfList = false;
    this.postIds = Array.from(document.querySelectorAll('.post-card')).map(el => el.id);

    if (this.hasTriggerTarget) {
      this.url = this.triggerTarget.dataset['url'];
      this.page = parseInt(this.triggerTarget.dataset['page']);
    }

    this.binds();
  }

  binds() {
    if (this.hasTriggerTarget) {
      window.addEventListener('scroll', this.windowScrolled.bind(this))
    }
  }

  windowScrolled(event) {
    event.preventDefault();

    if (isElementInViewport(this.triggerTarget, { topOffset: 100 })) {
      if (!this.endOfList && this.state !== 'loading') {
        this.loadAndInsert();
      }
    }
  }

  loadAndInsert() {
    this.load(posts => {
      if (posts.length) {
        this.insertPosts(posts);
        this.updateUrl();
      } else {
        this.endOfList = true;
      }
    });
  }

  insertPosts(loadedPosts) {
    Array.from(loadedPosts)
      .filter(post => post.classList.contains('post-card'))
      .filter(post => !this.postIds.includes(post.id))
      .forEach(post => {
        this.postIds.push(post.id);
        new PostCard(post);

        this.postListTarget.appendChild(post);
      });
  }

  load(callback) {
    this.toLoading();

    const request = fetch(this.url, { method: 'GET', headers: { 'X-Requested-With': 'fetch' } })
      .then(response => response.text())
      .then(html => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return Array.from(div.querySelectorAll('.post-card'));
      })
      .then(callback)
      .catch(error => {
        console.error('Error:', error)
      })
      .finally(() => this.toLoaded());

    return request
  }

  updateUrl() {
    this.page += 1;
    this.url = this.url.replace(/page=\d+/, `page=${this.page}`);
  }

  toLoaded() { this.state = 'loaded' }
  toLoading() { this.state = 'loading' }
}
