export default function isElementInViewport(el, options = {}) {
  const topOffset = options.topOffset || 0;
  const leftOffset = options.leftOffset || 0;

  const r = el.getBoundingClientRect();
  const rect = { top: r.top, height: r.height, left: r.left, width: r.width };

  const topMenu = document.querySelector('.top-menu');
  if (topMenu) {
    rect.top = rect.top + topMenu.clientHeight;
  }

  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const vertInView = rect.top <= windowHeight + topOffset && rect.top + rect.height + topOffset >= 0;
  const horInView = rect.left <= windowWidth + leftOffset && rect.left + rect.width + leftOffset >= 0;

  return vertInView && horInView;
}
