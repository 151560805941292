import Data from '../data.js.erb';
import getCurrentCity from './get_current_city';

export default function changeUrlSubdomain(url, subdomain) {
  const currentCity = getCurrentCity();
  const r = Data.urlRegExpParts;
  let match = url.match(new RegExp(`(${r.schema})(${r.hostname})(${r.port})?(${r.path})?(${r.query})?`));

  const schema = match[1];
  let hostname = match[2];
  const port = match[3] || '';
  let path = match[4] || '';
  // const query = match[5] || '';

  let hostnameParts = hostname.split('.');
  if (hostnameParts.length === 3) {
    hostnameParts[0] = subdomain;
  } else {
    hostnameParts = [subdomain].concat(hostnameParts);
  }
  hostname = hostnameParts.join('.');

  // remove city slug
  if (currentCity) {
    path = path.replace(`/${currentCity.slug}`, '');
  }

  // remove post number
  if (match = path.match(/(.*)\/([tabsrp][0-9\-]+)$/)) {
    path = match[1];
  }

  return schema + hostname + port + path;
};
