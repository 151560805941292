import authenticityToken from '../shared/helpers/authenticity_token'

export default class StateSwitcher {
  constructor(el) {
    this.el = el;
    this.url = el.dataset['url'];
    this.durationRequired = el.dataset.hasOwnProperty('durationRequired');

    if (this.direction() !== 'on' && this.direction() !== 'off') {
      throw ('Wrong switcher direction')
    }

    el.addEventListener('click', (e) => this.clicked(e));
  }

  clicked(e) {
    e.preventDefault();

    if (!this.isDisabled()) {
      this.disable();
      this.switch();
    }
  }

  switch() {
    if (this.isOn()) {
      this.switchOff()
    } else {
      if (this.durationRequired) {
        this.askDuration((duration) => this.switchOn(duration));
      } else {
        this.switchOn()
      }
    }
  }

  // Show browser modal window to allow select duration in days from selector
  // and send the value to callback
  askDuration(callback) {
    const duration = parseInt(prompt('На сколько дней?'));
    if (duration) {
      callback(duration);
    }
  }

  switchOn(duration) {
    this.sendRequest(this.url, 'post', { duration: duration }, () => {
      this.setDirrection('on');
    });
  }

  switchOff() {
    this.sendRequest(this.url, 'delete', {}, () => {
      this.setDirrection('off');
    });
  }

  sendRequest(url, httpMethod, params = {}, callback) {
    let formData = new FormData();
    formData.append('authenticity_token', authenticityToken());
    if (params.duration) { formData.append('duration_in_days', params.duration) };

    const options = { method: httpMethod, body: formData, headers: { 'Accept': 'application/json' } };

    fetch(url, options)
      .then(function (response) {
        if (callback) { callback() };

        if (response.ok) {
          // console.log(response)
        } else {
          alert('Ошибка. Не удалось изменить состояние');
        }
      })
      .finally(() => this.enable());
  }

  direction() { return this.el.dataset['currentSwitcherDirection'] }
  setDirrection(direction) { this.el.dataset['currentSwitcherDirection'] = direction }

  isOn() { return this.el.dataset['currentSwitcherDirection'] === 'on' }
  isDisabled() { return this.el.classList.contains('-disabled') }

  disable() { this.el.classList.add('-disabled') }
  enable() { this.el.classList.remove('-disabled') }
}

