import { Controller } from "@hotwired/stimulus"
import renderTextTemplate from "../../src/shared/helpers/render_text_template"
import isElementInViewport from "../../src/shared/helpers/is_element_in_viewport"

// Connects to data-controller="form--check-limit"
export default class extends Controller {
  static messages = {
    info: "Максимум можно выбрать — ${overall}, уже выбрали — ${reached}.",
    hitLimit: "Выбрано максимальное количество — ${overall}."
  }

  static targets = ['message', 'checkbox', 'checkAll', 'checkAllHint', 'checkAllExtraDisabled']
  static values = {
    reached: Number,
    overall: Number,
    supportPhone: String,

    infoMessage: String,
    hitLimitMessage: String
  }

  connect() {
    this.update()
  }

  infoMessage(context) {
    const template = this.infoMessageValue || this.constructor.messages.info
    return renderTextTemplate(template, context)
  }

  hitLimitMessage(context) {
    const template = this.hitLimitMessageValue || this.constructor.messages.hitLimit
    return renderTextTemplate(template, context)
  }

  do(e) {
    this.countReachedAndUpdate()
  }

  checkAll(e) {
    const isChecked = e.currentTarget.checked
    this.checkAllExtraDisabledTargets.forEach(checkbox => checkbox.disabled = isChecked)
    this.checkboxTargets.forEach(checkbox => {
      if (isChecked) {
        checkbox.checked = false
        checkbox.disabled = true
      } else {
        checkbox.disabled = false
      }
    })
    this.checkAllHintTarget.classList.toggle('d-hidden', !isChecked)

    this.countReachedAndUpdate()
  }

  set({ overall, reached, support_phone }) {
    this.overallValue = overall
    this.reachedValue = reached
    this.supportPhone = support_phone

    this.update()
  }

  countReachedAndUpdate() {
    this.reachedValue = this.checkboxTargets.filter(checkbox => checkbox.checked && !checkbox.disabled).length
    this.update()
  }

  update() {
    this.updateMessage(this.reachedValue, this.overallValue, this.supportPhoneValue)
    this.checkMessageVisibility(this.reachedValue, this.overallValue)
    this.checkCheckboxesAvailability()
  }

  updateMessage(reached, overall, supportPhone) {
    let message

    if (this.isLimitHit()) {
      message = this.hitLimitMessage({ overall, supportPhone })
      this.messageTarget.classList.add('-hit-limit')
      this.highlight(this.messageTarget, '#faa')
    } else {
      this.messageTarget.classList.remove('-hit-limit')
      message = this.infoMessage({ overall, reached })
    }

    this.messageTarget.textContent = message
  }

  highlight(el) {
    if (!isElementInViewport(el)) {
      window.scrollTo({
        top: el.offsetTop - 60,
        behavior: 'smooth'
      })
    }
  }

  checkMessageVisibility(reached, overall) {
    if (reached * 1.6 >= overall) {
      this.messageTarget.classList.remove('d-hidden')
    } else {
      this.messageTarget.classList.add('d-hidden')
    }
  }

  checkCheckboxesAvailability() {
    if (this.isLimitHit()) {
      this.checkboxTargets.filter(checkbox => !checkbox.checked).forEach(checkbox => checkbox.disabled = true)
    } else {
      this.checkboxTargets.forEach(checkbox => this.enableCheckbox(checkbox))
    }
  }

  enableCheckbox(input) {
    if (input.dataset['alwaysDisabled'] || this.isCheckAllChecked()) return false

    input.disabled = false
  }

  isLimitHit() {
    return this.reachedValue >= this.overallValue
  }

  isCheckAllChecked() {
    if (this.hasCheckAllTarget && this.checkAllTarget.checked) {
      return true
    } else {
      return false
    }
  }
}
