import sortable from "html5sortable/dist/html5sortable.cjs";
import fetchJsonWithCsrf from "../shared/helpers/fetch_json_with_csrf";

var bind = function (fn, me) { return function () { return fn.apply(me, arguments); }; };

Sorting.prototype.DEFAULT_SORTABLE_LIST_SETTINGS = {
  handle: ".handle",
  items: "li",
  orientation: 'vertical'
};

function Sorting(containerOrSelector, pluralName, options) {
  this.pluralName = pluralName;
  this.options = options;
  this.update = bind(this.update, this);

  if (typeof (containerOrSelector) === 'string') {
    this.container = document.querySelector(containerOrSelector);
  } else {
    this.container = containerOrSelector;
  }
  this.options || (this.options = {});
  if (this.options.touch != null) {
    this.touch = this.options.touch;
    delete this.options.touch;
  }
}

Sorting.prototype.init = function () {
  this.sortableListSettings = Object.assign(this.DEFAULT_SORTABLE_LIST_SETTINGS, this.options);
  sortable(this.container, this.sortableListSettings)[0].addEventListener('sortupdate', this.update)
};

Sorting.prototype.sorting = function (items) {
  return items.map(function (item) {
    var match = item.id.match(/_(\d*)$/);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  });
};

Sorting.prototype.update = function (e) {
  var data = {
    sorting: this.sorting(e.detail.origin.items)
  };
  if (this.touch != null) {
    data.touch = this.touch;
  }

  fetchJsonWithCsrf(`/sortings/${this.pluralName}`, { method: 'PATCH', body: JSON.stringify(data) });
};

export default function (selector, pluralName, options) {
  const sorting = new Sorting(selector, pluralName, options);

  if (sorting.container) {
    return sorting.init()
  } else {
    return null;
  }
}
