import { Controller } from "@hotwired/stimulus"
import debounce from 'lodash/debounce'
import fetchWithCsrf from "../../src/shared/helpers/fetch_json_with_csrf"
import maskPhone from '../../src/shared/helpers/mask_phone';

// Connects to data-controller="form--anonymous-phone"
export default class extends Controller {
  static targets = [
    "plainInput", "captionInput", "whatsappInput",
    "errorMessages"
  ]
  static values = { id: String, userId: String }
  static outlets = ["form--phones"]

  connect() {
    if (this.hasPlainInputTarget) {
      maskPhone(this.plainInputTarget)

      this.plainInputTarget.addEventListener('input', (e) => this.debouncedSubmit())
      this.captionInputTarget.addEventListener('input', (e) => this.debouncedSubmit())
      this.whatsappInputTarget.addEventListener('input', (e) => this.debouncedSubmit())
    }
  }

  debouncedSubmit = debounce(this.submit, 500)

  attrs() {
    return {
      plain: this.plainInputTarget.value,
      caption: this.captionInputTarget.value,
      whatsapp: this.whatsappInputTarget.checked,
      user_id: this.userIdValue
    }
  }

  attr(name) {
    return this.attrs()[name]
  }

  submit() {
    const attrs = this.attrs()
    if (this.idValue !== "new") {
      this.update(attrs)
    } else {
      this.create(attrs)
    }
  }

  enableSubmitButton() {
    this.formPhonesOutlet.enableSubmitButton();
  }

  disableSubmitButton() {
    this.formPhonesOutlet.disableSubmitButton();
  }

  async create(attrs) {
    const body = JSON.stringify({ phone: attrs });
    try {
      this.disableSubmitButton();

      const response = await fetchWithCsrf(`/phones`, { method: 'POST', body: body })
      if (response.errors) {
        this.setErrors(response.errors)
      } else {
        this.idValue = response.id
        this.formPhonesOutlet.phoneAdded(this.idValue)
        this.resetErrors()
      }
      this.enableSubmitButton();
    } catch (error) {
      this.enableSubmitButton();
      // this.setBaseError('Не удалось сохранить телефон. Повторите попытку позже')
    }
  }

  async update(attrs) {
    const body = JSON.stringify({ phone: attrs });
    try {
      this.disableSubmitButton();
      const response = await fetchWithCsrf(`/phones/${this.idValue}`, { method: 'PATCH', body: body })
      if (response.errors) {
        this.setErrors(response.errors)
      } else {
        this.resetErrors()
      }
      this.enableSubmitButton();
    } catch (error) {
      this.enableSubmitButton();
      // this.setBaseError('Не удалось сохранить телефон. Повторите попытку позже')
    }
  }

  async destroy(e) {
    e.preventDefault()

    if (this.idValue == "new") {
      this.destroyStub()
      return
    }

    try {
      const response = await fetchWithCsrf(`/phones/${this.idValue}`, { method: 'DELETE' })
      if (response.ok) {
        this.formPhonesOutlet.phoneDeleted(this.idValue)

        this.element.remove();
        this.resetErrors();
      } else {
        this.setErrors('Нельзя удалить последний телефон из профиля')
      }
    } catch (error) {
      console.log('error', error)
      // this.setError('Не удалось удалить телефон. Повторите попытку позже')
    }
  }

  destroyStub() {
    this.removeElement();
    this.dispatch("destroy", { detail: {} });
  }

  removeElement() {
    this.element.remove()
  }

  // Errors
  setErrors(errors) {
    this.resetErrors()

    if (typeof errors === 'string') {
      this.setBaseError(errors)
      this.element.classList.add('-error')

      return
    }

    const baseErrors = []
    if (errors.plain) {
      this.plainInputTarget.classList.add('-error')
      baseErrors.push(this.inlineError(errors.plain, 'Телефон'))
    }
    if (errors.caption) {
      this.captionInputTarget.classList.add('-error')
      baseErrors.push(this.inlineError(errors.caption, 'Подпись'))
    }
    console.log('setErrors', errors)
    this.setBaseError(baseErrors.join('<br>'))
  }

  inlineError(error, attrName) {
    if (typeof error === 'string') {
      if (error.startsWith('^')) {
        return error.replace('^', '')
      } else {
        return `${attrName} ${error}`
      }
    } else {
      return error.map((e) => this.inlineError(e, attrName)).join('. ')
    }
  }

  setBaseError(message) {
    if (message && message.length) { this.errorMessagesTarget.innerHTML = message }
  }

  resetErrors() {
    this.errorMessagesTarget.innerText = '';
    this.element.classList.remove('-error')
    this.element.querySelectorAll('.-error').forEach((el) => el.classList.remove('-error'))
  }
}
