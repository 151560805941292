module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  console.log('collections', collections) 
    ; __append("\n  ")
    ;  collections.forEach(function(collection) { 
    ; __append("\n    ")
    ;  if (collection['items'] && collection['items'].length> 0) { 
    ; __append("\n      ")
    ;  if (collection['header']) { 
    ; __append("\n        <div class=\"header\">\n          ")
    ; __append(escapeFn( collection['header'] ))
    ; __append("\n        </div>\n        ")
    ;  } 
    ; __append("\n\n          <ol class=\"items\">\n            ")
    ;  collection['items'].forEach(function(item) { 
    ; __append("\n              <li data-url=\"")
    ; __append(escapeFn( item.url ))
    ; __append("\" role=\"option\" data-main-search-target=\"item\">\n                <div class=\"title\">\n                  ")
    ; __append(escapeFn( item.title ))
    ; __append("\n                </div>\n                <div class=\"caption\">\n                  ")
    ; __append(escapeFn( item.caption ))
    ; __append("\n                </div>\n                <div class=\"locality\">\n                  ")
    ; __append(escapeFn( item.locality ))
    ; __append("\n                </div>\n                </a>\n              </li>\n              ")
    ;  }) 
    ; __append("\n          </ol>\n          ")
    ;  } 
    ; __append("\n            ")
    ;  }) 
    ; __append("\n")
  }
  return __output;

}