import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sorting"
export default class extends Controller {
  // defines stimulus values

  static values = { collection: String, touch: String }

  connect() {
    let options = {}
    if (this.hasTouchValue) {
      options.touch = this.touchValue
    }
    new Sti.Lib.sorting(this.element, this.collectionValue, options)
  }
}
