import { Controller } from "@hotwired/stimulus"
import fetchWithCsrf from "../src/shared/helpers/fetch_json_with_csrf"

// Connects to data-controller="logo"
export default class extends Controller {
  static targets = ["file", "uploadArea", "displayedArea"]
  static values = {
    imageableId: Number,
    imageableType: String,
    photoType: String
  }

  connect() {
    this.initFileUploader()
  }

  initFileUploader() {
    const formData = {
      type: this.photoTypeValue,
      "image[imageable_id]": this.imageableIdValue,
      "image[imageable_type]": this.imageableTypeValue
    }

    Sti.Lib.fileUpload(this.fileTarget,
      {
        dataType: 'json',
        url: '/images',
        method: 'POST',
        formData: formData,
        start: () => this.startUploading(),
        success: (photo) => {
          this.insertPhotoField(photo)
          this.finishUploading()
        },
        error: (errorMessage) => {
          alert(errorMessage)
          this.finishUploading()
        }
      }
    )
  }

  startUploading() {
    this.uploadAreaTarget.classList.add('-uploading')
  }

  insertPhotoField(photo) {
    const newField = this.renderPhotoField(photo)
    this.hideUploadArea()
    this.uploadAreaTarget.insertAdjacentHTML('beforebegin', newField);
  }

  finishUploading() {
    this.uploadAreaTarget.classList.remove('-uploading')
  }

  renderPhotoField(photo) {
    return `<div class="photo-field" data-logo-target="displayedArea">
      <img src=${photo.file.cropped.url} />
      <a class="btn btn-danger btn-mini remove" href="/images/${photo.id}" data-action="logo#remove">
        <i class="bi bi-trash"></i>
      </a>
    </div>`
  }

  remove(event) {
    event.preventDefault()
    const btn = event.currentTarget
    const url = btn.getAttribute('href')

    fetchWithCsrf(url, { method: 'DELETE' })
      .then(response => {
        if (response.ok) {
          this.showUploadArea()
          this.displayedAreaTarget.remove()
        }
      })
  }

  hideUploadArea() {
    this.uploadAreaTarget.classList.add('d-hidden')
  }

  showUploadArea() {
    this.uploadAreaTarget.classList.remove('d-hidden')
  }
}
